section {
  display: flex;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

ul {
  width: 100%;
}

li {
  min-height: 28px;
  padding-left: 14px;
}

li div {
  align-items: center;
  display: flex;
  height: 36px;
  padding: 0 100%;
  margin: 0 -100%;
  transition: all .2s ease-in-out;
}

li div > img {
  margin-right: 10px;
  /* width: 14px; */
  user-select: none;
}

li div > h3 {
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  white-space:  nowrap;
}

li:hover {
  cursor: pointer;
}

.file-explorer {
  /* border: 1px solid #ddd; */
}

.file-explorer-sidebar {
  height: auto;
  width: 100%;
}

/* .folder-area { */
  /* border-left: 1px solid #ddd; */
/* } */

.active {
  color: #1b55e3 !important;
  font-weight: bold !important;
}
