.Resizeable-content {
  position: relative;
}

.Resize-bar {
  align-items: center;
  bottom: 0;
  cursor: ew-resize;
  display: flex;
  height: 100%;
  justify-content: space-around;
  position: absolute;
  right: 2px;
  top: 0;
  width: 9px;
  z-index: 1;
}

.Resize-bar > div {
  height: 40px;
  width: 1px;
  background: #c5c5c5;
}
