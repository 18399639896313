@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* BADGE */

.badge {
  font-weight: normal;
  background: $white;
  color: $secondary;
  letter-spacing: 1px;
  text-transform: uppercase;

  &.badge-default {
    background: $default;
    color: $white;
  }
  &.badge-primary {
    background: $primary;
    color: $white;
  }
  &.badge-secondary {
    background: $secondary;
    color: $white;
  }
  &.badge-success {
    background: $success;
    color: $white;
  }
  &.badge-danger {
    background: $danger;
    color: $white;
  }
  &.badge-warning {
    background: $warning;
    color: $white;
  }
  &.badge-info {
    background: $info;
    color: $white;
  }
  &.badge-light {
    background: $light;
    color: $text;
  }
  &.badge-dark {
    background: $dark;
    color: $white;
  }
}
