@import 'import/core.scss';
@import 'import/layout.scss';
@import 'import/utils.scss';
@import 'import/measurements.scss';
@import 'import/colors.scss';
@import 'import/router.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: メイリオ, Meiryo, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック,
    Geneva, Arial, Verdana, sans-serif !important;
}

.context-menu {
  margin: 0;
  padding: 5px;
  overflow: hidden;
  background: #fff;
  z-index: 1000;
  opacity: 1;
  border: 1px solid rgba(64, 87, 109, 0.07);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(53, 71, 90, 0.2);
  -webkit-transform: translate(0, 15px) scale(0.95);
  transform: translate(0, 15px) scale(0.95);
  -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
}

.context-menu-button {
  color: #0e1318;
  font-size: 15px;
  height: 45px;
  border: 0;
  width: 170px;
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
  white-space: nowrap;
  background: none;
  line-height: normal;
}

.context-menu-button:hover {
  background-color: #394c6026;
  outline: none;
  border: none;
  color: #0e1318;
}

.context-menu-button:focus {
  outline: 0;
  color: #0e1318;
}

.context-menu-button::-moz-focus-inner {
  border: 0;
  padding: 0;
  color: #0e1318;
}

@media (max-width: 610px) {
  .hide-phone-component {
    display: none;
  }
}

@media (max-width: 450px) {
  .ac-logo-text {
    display: none;
  }

  .ac-logo-notext {
    display: block;
  }
}

@media (min-width: 451px) {
  .ac-logo-text {
    display: block;
  }

  .ac-logo-notext {
    display: none;
  }
}

.folder-name-structure:hover {
  color: rgba(10, 10, 255, 0.7);
}

.ant-tabs-nav-wrap {
  place-content: center !important;
}

.btn-hover:hover {
  color: black !important;
  border-color: #e4e9f0;
}

.btn-hover:active {
  color: black !important;
}

.btn-hover::selection {
  color: black !important;
}

.btn-hover:focus {
  color: black !important;
}

.btn-hover {
  --antd-wave-shadow-color: transparent;
}

.menu-hover:hover {
  background-color: white;
}

.ant-dropdown-menu-submenu-title {
  justify-content: center;
}

.center-cropped {
  position: relative;
  height: 100px;
  margin-top: 20px;
}
.center-cropped img {
  position: absolute;
  // top: 20;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.hide-overflow::-webkit-scrollbar {
  display: none;
}

.hide-overflow {
  -ms-overflow-style: none;
}

.folder-drag-hover {
  color: rgba(31, 75, 219, 0.7) !important;
}

.folder-drag-hover span {
  color: rgba(31, 75, 219, 0.7) !important;
}

.active span {
  color: #1b55e3 !important;
}

.transparent-card {
  background-color: transparent !important;
}

.hover-checkbox {
  display: none !important;
}

.hover-eye {
  display: none !important;
}

.card-hover > .ant-card-cover > .hover-checkbox {
  display: inherit !important;
}

.card-hover > .ant-card-cover > .hover-eye {
  display: inherit !important;
}

.card-selected > .ant-card-cover > .hover-checkbox {
  display: inherit !important;
}

.card-selected {
  background-color: rgba(111, 122, 223, 0.123) !important;
}

.pg-viewer-wrapper {
  text-align: center !important;
}

.pg-viewer-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.pg-viewer-wrapper::-webkit-scrollbar {
  background-color: transparent;
}

.pg-viewer-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ant-card-body {
  padding: 15px 0 0 0 !important;
}

.ant-card-meta-description {
  font-size: 12px !important;
}

.ant-modal-footer {
  border: 0;
  text-align: center;
}

.file-preview-modal > .ant-modal > .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.5);
}

.file-preview-modal > .ant-modal > .ant-modal-content > .ant-modal-header {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 0;
}

.file-preview-modal > .ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  color: white;
}

.file-preview-modal > .ant-modal > .ant-modal-content > .ant-modal-close {
  color: white;
}

.ant-select-dropdown-menu-item {
  text-align: right;
}

.ant-select-selection__rendered {
  float: right;
  padding-right: 15px;
}

.ant-select-selection-placeholder {
  right: 50px;
  left: auto;
  overflow: inherit;
  color: rgba(0, 0, 0, 0.65);
  opacity: 1;
  padding-right: 10px !important;
}

.ant-select-arrow {
  margin-top: -7.5px;
}

.ant-select-selector {
  border: 0 !important;
}

.ant-upload-text {
  // font-weight: bold;
  color: #666464;
  font-size: 20px;
  padding-top: 2vh;
}

.upload-zone {
  margin-top: 3vh;
  height: 32vh;
  width: 100%;
  border: 1px dashed #ababab;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-top: 11vh;
  min-height: 300px;
}

.upload-zone:hover {
  cursor: pointer;
}

.drop-hover {
  margin-top: 3vh;
  height: 32vh;
  width: 100%;
  border: 1px dashed #ababab;
  background-color: #ecf3fa;
  border-radius: 5px;
  padding-top: 11vh;
  min-height: 300px;
}

.drop-hover:hover {
  cursor: pointer;
}

.bookmark-description {
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-family: メイリオ, Meiryo, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック,
    Geneva, Arial, Verdana, sans-serif !important;
  padding: 28px;
}

.bookmark-key {
  padding-top: 5px;
  text-align: center;
  font-weight: bolder;
  border-radius: 2px;
  width: 120px;
  height: 50px;
  margin: 0 7px;
  background: #f2f3f4;
}

.folder-container {
  text-align: center;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0 40px 0;
  min-height: 100%;
}

.button-trash-bin {
  border: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  margin-left: -4px;
  color: #495057;
}

.mr-0 {
  margin-right: 35% !important;
}

.free-border {
  border: 1px black solid;
  border-radius: 3px;
  color: black;
  padding: 3px;
}

.alert {
  color: black;
}

.auto-width {
  width: auto;
}

.ant-table-thead > tr > th {
  background-color: white !important;
  color: rgb(120, 120, 120) !important;
}

.defocus:focus {
  outline: 0 !important;
}

.ant-table-placeholder {
  border-bottom: 0 !important;
}

.style_inner__1Jr_U {
  border-top: 1px !important;
}

#menu-left-container {
  li {
    padding-left: 0 !important;
  }
}

.header-logo {
  img.logo {
    width: 200px;
  }
}

.tag-input {
  line-height: 1.4;
  .is-invalid {
    .ac-data__control {
      background-color: #fff;
      border-color: #fb434a;
    }
  }
}

.title-bold {
  font-weight: bold !important;
}

.ant-input {
  font-size: 14px !important;
}

.ant-input-group-addon {
  border: 0 solid #e4e9f0 !important;
  padding: 0 !important;
}

.ant-popover-inner-content > .ant-input-group-wrapper > .ant-input-group > .ant-input-group-addon {
  padding: 0 13px !important;
}

.upload-button {
  height: 5vh;
  width: 10vw;
  min-width: 125px;
  min-height: 40px;
  font-size: large;
  font-weight: bolder;
  padding: 0.375rem 0.375rem !important;
}

.upload-button-small {
  padding: 0 15px !important;
}

.text-bolder {
  font-weight: bolder;
  font-size: 16px;
}

.drop-zone {
  position: relative;
}

.ant-tabs-bar {
  border-bottom: 0 !important;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

body .ant-tabs-nav .ant-tabs-tab {
  border: 0 !important;
  margin-bottom: 1px !important;
  color: #1b55e3 !important;
  margin-right: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  height: 35px !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 2px solid #1b55e3 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    // text-shadow: 0 0 0.25px currentcolor;
}

body .ant-tabs-nav .ant-tabs-tab:hover {
  color: white !important;
  background: rgb(88, 133, 248) !important;
}

body .ant-tabs-nav .ant-tabs-tab-active {
  color: white !important;
  background: #1b55e3 !important;
  border: 0 !important;
  margin-bottom: 1px !important;
  margin-right: 10px !important;
}

body .ant-tabs-nav .ant-tabs-tab-active:hover {
  color: white !important;
  background: #1b55e3 !important;
}

.ant-modal {
  width: fit-content !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}

#header.header-scrolled,
#header.header-pages {
  height: 70px;
  padding-bottom: 15px;
  background-color: #fff;
  top: 0;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.3);
}

#header.header-scrolled #topbar,
#header.header-pages #topbar {
  display: none;
}

#header .logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #413e66;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  max-height: 35px;
}

#footer {
  padding: 0 0 30px 0;
  font-size: 14px;
  background: #000;
  margin: -1px -15px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #000;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  color: #d5d5d5;
  font-weight: 400;
  letter-spacing: 3px;
}

#footer .footer-top .footer-info p {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: メイリオ, Meiryo, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック,
    Geneva, Arial, Verdana, sans-serif;
  color: #535074;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #4d4d4d;
  color: #000;
  line-height: 1;
  padding: 10px 0;
  margin-right: 10px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a.comment {
  width: 160px;
  border-radius: 5px;
  color: #b9b9b9;
}

#footer .footer-top .social-links a:hover {
  background: #1b55e9;
  color: #fff;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #413e66;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}

#footer .footer-top .footer-links {
  margin-bottom: 20px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul li {
  padding: 8px 0;
  display: inline-block;
  margin-right: 1rem;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #949494;
}

#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type='email'] {
  padding: 6px 8px;
  width: 65%;
  border: 1px solid #d9dde6;
}

#footer .footer-top .footer-newsletter input[type='submit'] {
  background: #006496;
  border: 1px solid #006496;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type='submit']:hover {
  background: #1b55e9;
}

#footer .footer-top .form .form-group {
  margin-bottom: 15px;
}

#footer .footer-top .form #sendmessage {
  color: #fff;
  background: #006496;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#footer .footer-top .form #errormessage {
  color: #fff;
  display: none;
  background: red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#footer .footer-top .form #sendmessage.show,
#footer .footer-top .form #errormessage.show,
#footer .footer-top .form .show {
  display: block;
}

#footer .footer-top .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#footer .footer-top .form label {
  color: #413e66;
  font-weight: 500;
}

#footer .footer-top .form input,
#footer .footer-top .form textarea {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #dce1ec;
  font-size: 14px;
}

#footer .footer-top .form input::-webkit-input-placeholder,
#footer .footer-top .form textarea::-webkit-input-placeholder {
  color: #a2a0bd;
}

#footer .footer-top .form input::-moz-placeholder,
#footer .footer-top .form textarea::-moz-placeholder {
  color: #a2a0bd;
}

#footer .footer-top .form input:-ms-input-placeholder,
#footer .footer-top .form textarea:-ms-input-placeholder {
  color: #a2a0bd;
}

#footer .footer-top .form input::placeholder,
#footer .footer-top .form textarea::placeholder {
  color: #a2a0bd;
}

#footer .footer-top .form button[type='submit'] {
  background: #006496;
  border: 0;
  border-radius: 3px;
  padding: 8px 30px;
  color: #fff;
  transition: 0.3s;
}

#footer .footer-top .form button[type='submit']:hover {
  background: #1b55e9;
  cursor: pointer;
}

#footer .copyright {
  text-align: center;
  padding-top: 20px;
  color: #535074;
  font-size: 16px;
}

#footer .credits {
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  color: #949494;
}

#footer .credits a {
  color: #d4d4d4;
}

#footer .credits a:hover {
  color: #949494;
}

.section-bg {
  background: #f5f5f5;
}

@media (max-width: 574px) {
  #footer .footer-top .social-links a {
    margin-bottom: 25px;
  }
}

.main-pages {
  margin-top: 60px;
}

.sns-icon {
  position: absolute;
  right: 30px;
  top: -25px;
}

.main-nav,
.main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.main-nav a {
  display: block;
  position: relative;
  color: #666464;
  padding: 10px 15px;
  transition: 0.3s;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.main-nav div {
  display: block;
  position: relative;
  color: #666464;
  padding: 10px 15px;
  transition: 0.3s;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.main-nav a:hover,
.main-nav .active > a,
.main-nav li:hover > a {
  color: #006496;
  text-decoration: none;
}

.freebie-site-links {
  margin-bottom: -3px;
}
.freebie-site-links a {
  text-decoration: none;
  color: #333;
  font-size: 13px;
  height: 32px;
}
.freebie-site-links a:hover {
  background-color: #f2f2f2;
}
.freebie-site-links a > img {
  height: 13px;
  vertical-align: middle;
}
.freebie-site-links a.link-site-default {
  border-bottom: 3px solid #666;
}
.freebie-site-links a.link-site-illust {
  border-bottom: 3px solid #d73a4e;
}
.freebie-site-links a.link-site-photo {
  border-bottom: 3px solid #e7c700;
}
.freebie-site-links a.link-site-silhouette {
  border-bottom: 3px solid #10a1a2;
}
.freebie-site-links a.link-site-freebieac {
  border-bottom: 3px solid #2f6dbc;
}
.freebie-site-links a.link-site-newyear {
  border-bottom: 3px solid #e48f9e;
}
.freebie-site-links a.link-site-faq {
  border-bottom: 3px solid #a9e0f2;
}
.freebie-site-links nav a.link-site-illust:after {
  content: ' イラストAC';
}
.freebie-site-links nav a.link-site-photo:after {
  content: ' 写真AC';
}
.freebie-site-links nav a.link-site-silhouette:after {
  content: ' シルエットAC';
}
.freebie-site-links nav a.link-site-freebieac:after {
  content: ' フリービーAC';
}
.freebie-site-links nav a.link-site-newyear:after {
  content: ' 年賀状AC';
}
.freebie-site-links nav a.link-site-faq:after {
  content: ' FAQ';
}
.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
}
@media (max-width: 1200px) {
  .freebie-site-links nav a[class^='p-1 link-site-']:after {
    content: '';
  }
  .freebie-site-links a > img {
    height: 90%;
  }
}
@media (max-width: 800px) {
  .link-site-affiliate {
    display: none;
  }
}
@media (max-width: 580px) {
  .link-site-members {
    display: none;
  }
}

.intronew {
  position: relative;
}
.intronew img.intronew-bg {
  width: 100%;
  height: auto;
}
.intronew .intronew-infobox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.intronew .intronew-infobox h2 {
  font-size: 48px;
  color: black;
  font-weight: 300;
}
.intronew .intronew-infobox .btn-get-started {
  width: 280px;
  display: flex;
  align-items: center;
}
.intronew .intronew-infobox .btn-get-started img {
  max-width: 35px;
  margin-right: 10px;
}

@media (max-width: 1368px) {
  .intronew .intronew-infobox h2 {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .intronew .intronew-infobox h2 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .intronew .intronew-infobox h2 {
    font-size: 25px;
  }
  .intronew .intronew-infobox .btn-get-started {
    width: 200px;
    font-size: 12px;
    padding: 5px 15px;
  }
  .intronew .intronew-infobox .btn-get-started img {
    max-width: 30px;
  }
  #google_translate_element {
    position: absolute;
    right: 1%;
    top: 37px;
    z-index: 100;
  }
}
@media (max-width: 560px) {
  .intronew .intronew-infobox h2 {
    font-size: 18px;
  }
  .page-link {
    padding: 0.5rem 0.6rem;
  }
}

@media (max-width: 375px) {
  .intronew .intronew-infobox .btn-get-started {
    width: 180px;
    font-size: 11px;
    padding: 3px 10px;
  }
}

#why-us {
  padding: 60px 0;
}

#why-us .why-us-content .features {
  margin: 0 0 15px 0;
  padding: 0;
  transition: 0.3s ease-in-out;
}

#why-us .why-us-content .features i {
  font-size: 36px;
  float: left;
}

#why-us .why-us-content .features h4 {
  font-size: 24px;
  font-weight: 600;
  margin-left: 56px;
  color: #413e66;
  margin-bottom: 5px;
}

#why-us .why-us-content .features p {
  font-size: 16px;
  margin-left: 56px;
  color: #555186;
}

#why-us .counters {
  padding-top: 40px;
}

#why-us .counters span {
  font-family: メイリオ, Meiryo, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック,
    Geneva, Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 48px;
  display: block;
  color: #555186;
}

#why-us .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: メイリオ, Meiryo, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック,
    Geneva, Arial, Verdana, sans-serif;
  font-size: 16px;
  color: #8a87b6;
}

.paragraph-lh {
  line-height: 2.2;
}

.header-lh {
  line-height: 1.5;
}

.white-text.ant-progress .ant-progress-text {
  color: white;
}

.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 1px solid #fff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}
