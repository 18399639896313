@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* LIST GROUP */

.list-group-item {
  border-color: $border;

  &.active {
    background: $primary;
  }
}
